import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
         <path d="M7720 8584 c0 -13 -7 -15 -37 -10 -28 5 -34 4 -23 -3 8 -6 70 -11
138 -11 74 0 122 -4 122 -10 0 -5 -15 -10 -34 -10 -19 0 -37 -4 -40 -8 -3 -5
13 -7 34 -5 24 2 40 0 40 -6 0 -6 18 -11 40 -11 34 0 40 3 40 21 0 19 4 21 34
16 24 -4 36 -1 40 8 5 15 -15 25 -47 24 -21 0 -21 -1 -2 -10 16 -7 12 -8 -14
-4 -18 3 -90 6 -160 6 -69 1 -118 3 -108 6 23 6 22 23 -3 23 -12 0 -20 -7 -20
-16z"/>
<path d="M7928 8583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7568 8573 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8103 8563 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7437 8543 c3 -7 6 -13 8 -13 1 0 7 6 13 13 8 10 6 13 -7 13 -11 0
-17 -6 -14 -13z"/>
<path d="M7525 8549 c-3 -4 -2 -14 1 -23 6 -17 59 -23 68 -8 3 5 27 5 53 1 70
-11 183 -11 183 1 0 9 -94 15 -175 12 -26 -1 -28 0 -10 8 17 9 17 10 -7 7 -16
-3 -28 -1 -28 2 0 9 -80 9 -85 0z"/>
<path d="M7300 8530 c-8 -5 -11 -10 -7 -10 4 0 -5 -11 -20 -25 -22 -19 -24
-24 -10 -25 9 0 17 5 17 10 0 6 9 10 20 10 13 0 18 5 14 15 -5 11 1 15 22 15
20 0 25 -3 15 -9 -19 -12 3 -24 29 -16 30 10 24 32 -11 39 -42 8 -51 8 -69 -4z"/>
<path d="M8160 8530 c0 -6 23 -10 51 -10 36 0 55 -5 63 -16 7 -9 28 -16 49
-17 20 0 37 -5 37 -10 0 -4 -6 -5 -12 -2 -7 3 -4 0 6 -7 11 -7 27 -21 37 -30
13 -12 20 -14 25 -6 4 7 3 13 -3 15 -6 2 -9 14 -7 26 2 12 2 16 -2 10 -3 -7
-13 -13 -22 -13 -13 0 -14 2 -4 9 10 6 5 11 -17 21 -17 6 -34 12 -38 12 -4 0
-16 4 -26 10 -25 13 -137 19 -137 8z"/>
<path d="M7215 8510 c-3 -5 3 -10 14 -10 11 0 23 5 26 10 3 6 -3 10 -14 10
-11 0 -23 -4 -26 -10z"/>
<path d="M8225 8490 c-17 -7 -17 -9 -2 -9 10 -1 17 -9 17 -20 0 -13 9 -20 28
-23 39 -6 52 0 52 23 0 14 -7 19 -30 19 -16 0 -30 5 -30 10 0 12 -8 12 -35 0z"/>
<path d="M8098 8473 c-51 -21 -48 -33 10 -34 26 0 55 -2 66 -4 33 -6 46 4 28
24 -17 17 -67 17 -56 0 3 -5 -6 -9 -20 -9 -34 0 -33 13 2 27 15 6 23 11 17 11
-5 1 -27 -6 -47 -15z"/>
<path d="M7325 8470 c27 -12 35 -12 35 0 0 6 -12 10 -27 9 -25 0 -26 -1 -8 -9z"/>
<path d="M7375 8470 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M7836 8463 c-4 -9 -28 -13 -76 -13 -40 0 -70 -4 -70 -10 0 -6 30 -10
70 -10 39 0 70 3 70 8 0 4 25 5 55 4 35 -3 55 0 55 7 0 6 -11 11 -24 11 -14 0
-27 4 -31 9 -8 14 -44 9 -49 -6z"/>
<path d="M7437 8449 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8430 8450 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M8000 8430 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
<path d="M7483 8434 c3 -4 21 -8 39 -10 24 -3 29 -1 18 6 -16 11 -67 14 -57 4z"/>
<path d="M8496 8420 c12 -12 28 -20 35 -18 9 3 0 12 -21 22 l-35 18 21 -22z"/>
<path d="M8425 8410 c-3 -5 -1 -10 4 -10 6 0 11 -18 11 -40 0 -33 3 -40 20
-40 15 0 20 7 20 28 0 21 2 24 10 12 8 -13 10 -12 10 3 0 9 -4 17 -10 17 -5 0
-9 3 -9 8 3 20 -3 32 -17 32 -9 0 -12 -5 -8 -12 6 -10 4 -10 -8 0 -11 9 -18
10 -23 2z"/>
<path d="M7928 8403 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7568 8393 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7622 8389 c2 -6 33 -23 69 -38 36 -14 83 -41 105 -59 25 -20 40 -28
43 -20 2 7 -1 14 -7 16 -17 5 -15 22 3 22 8 0 17 -12 21 -27 10 -49 13 -53 34
-53 l20 0 -19 39 c-11 21 -18 41 -15 44 3 2 -21 8 -53 12 -32 4 -66 13 -77 21
-11 8 -31 14 -43 14 -28 0 -29 8 -5 26 16 12 15 13 -5 14 -13 0 -23 -4 -23
-10 0 -6 -9 -6 -26 0 -18 7 -25 7 -22 -1z"/>
<path d="M7753 8393 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"/>
<path d="M7841 8369 c-5 -16 -7 -28 -3 -28 4 1 10 0 15 -1 19 -6 33 -5 40 5 4
6 10 5 17 -5 8 -12 9 -9 8 11 -2 21 -10 29 -35 37 -30 9 -32 8 -42 -19z"/>
<path d="M8582 8359 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M8562 8339 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M8630 8330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8660 8285 c0 -8 -4 -16 -10 -16 -5 -1 -14 -2 -19 -3 -19 -2 -23 -16
-7 -22 10 -4 22 -2 28 4 8 8 17 8 30 1 14 -8 18 -6 18 9 0 18 -18 42 -32 42
-4 0 -8 -7 -8 -15z"/>
<path d="M7941 8230 c-14 -3 -26 -11 -29 -19 -3 -10 0 -11 18 -1 24 13 50 7
50 -11 0 -6 -9 -9 -19 -6 -14 3 -18 1 -14 -9 3 -8 14 -14 24 -14 14 0 19 -7
20 -27 0 -25 1 -26 9 -8 8 20 9 19 9 -1 1 -12 9 -28 18 -35 15 -13 16 -11 10
11 -4 14 -7 31 -7 38 0 6 -7 12 -15 12 -10 0 -15 10 -15 28 0 32 -25 51 -59
42z"/>
<path d="M8100 8225 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M8754 8194 c3 -9 6 -18 6 -20 0 -3 4 -3 10 1 11 7 2 35 -12 35 -6 0
-7 -7 -4 -16z"/>
<path d="M8054 8168 c2 -18 4 -36 5 -40 0 -5 6 -8 12 -8 6 0 8 9 4 21 -5 13
-3 19 3 15 14 -9 23 19 9 33 -22 22 -37 12 -33 -21z"/>
<path d="M8721 8184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6800 8184 c-132 -16 -393 -81 -546 -135 -329 -116 -700 -314 -1024
-545 -70 -50 -181 -134 -246 -187 -116 -92 -326 -286 -312 -287 4 0 13 6 20
13 85 78 409 289 538 349 202 95 202 94 355 150 193 70 275 93 535 148 158 34
335 51 530 52 268 1 479 -28 660 -90 233 -81 418 -225 508 -399 24 -46 46 -83
49 -83 9 0 -6 183 -23 285 -63 379 -272 622 -607 706 -62 16 -122 22 -242 24
-88 2 -176 1 -195 -1z"/>
<path d="M8146 8162 c-4 -7 -5 -16 -1 -21 3 -6 -3 -8 -13 -6 -34 6 -44 -10
-20 -31 18 -15 20 -15 9 -1 -12 15 -11 17 8 17 22 0 36 20 29 42 -3 9 -6 9
-12 0z"/>
<path d="M8756 8123 c1 -26 -4 -40 -15 -45 -9 -4 -1 -6 19 -4 20 1 41 6 49 10
7 4 15 5 18 2 4 -3 1 -6 -5 -6 -7 0 -12 -7 -12 -15 0 -8 -4 -15 -8 -15 -10 0
-13 -25 -6 -57 5 -28 24 -30 24 -3 0 14 5 18 18 13 14 -5 15 -3 5 8 -16 16
-17 69 -2 69 6 0 4 7 -5 15 -8 8 -20 15 -26 15 -7 0 -10 8 -7 19 3 12 -1 22
-10 25 -31 12 -38 6 -37 -31z"/>
<path d="M8050 8085 c-14 -16 -10 -18 14 -9 9 3 16 10 16 15 0 14 -16 11 -30
-6z"/>
<path d="M8095 8070 c-3 -5 -12 -7 -20 -3 -24 9 -17 -14 12 -36 14 -12 23 -24
20 -28 -4 -3 4 -20 16 -37 13 -17 26 -39 30 -49 4 -11 12 -15 19 -11 9 6 9 9
0 12 -7 2 -10 8 -6 13 3 5 1 9 -3 9 -5 0 -17 14 -26 30 -15 25 -16 30 -3 30 8
0 17 -6 19 -12 3 -7 6 2 6 20 1 29 -1 32 -18 22 -27 -14 -31 -13 -31 6 0 9 8
23 18 30 16 12 15 13 -5 14 -12 0 -24 -4 -28 -10z"/>
<path d="M8840 8051 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M8229 8048 c-1 -2 -3 -22 -4 -45 -2 -29 3 -47 13 -55 12 -10 14 -10
8 0 -4 7 -2 12 4 12 6 0 9 4 5 9 -3 5 -1 12 5 16 6 4 2 14 -10 27 -11 12 -17
25 -14 30 3 4 3 8 0 8 -3 0 -7 -1 -7 -2z"/>
<path d="M8865 8010 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M8873 7956 c1 -20 4 -36 7 -36 8 0 5 -99 -3 -107 -4 -3 -7 0 -7 8 0
23 -17 42 -29 34 -9 -5 -8 -13 3 -31 9 -13 16 -32 16 -44 0 -11 4 -20 9 -20 6
0 11 -19 13 -42 3 -24 4 -8 5 35 0 73 10 109 20 79 4 -9 8 -10 18 -2 11 9 9
22 -6 72 -10 34 -25 68 -32 76 -13 12 -15 9 -14 -22z m47 -87 c0 -5 -4 -9 -10
-9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z"/>
<path d="M8231 7904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8187 7903 c-4 -3 -7 -15 -7 -26 0 -13 5 -17 15 -13 9 3 15 0 15 -9
0 -8 5 -15 10 -15 6 0 10 7 10 15 0 20 -34 57 -43 48z"/>
<path d="M8284 7883 c-10 -10 -20 -22 -21 -28 -1 -5 -4 -17 -8 -26 -3 -9 2
-24 12 -35 10 -11 17 -18 16 -16 -1 2 7 12 18 23 20 20 26 64 9 74 -6 4 -8 11
-5 16 9 14 0 11 -21 -8z"/>
<path d="M8210 7809 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M8232 7778 c-15 -15 -16 -32 -2 -23 6 3 10 1 10 -5 0 -6 5 -8 10 -5
12 7 14 45 2 45 -4 0 -13 -5 -20 -12z"/>
<path d="M8900 7775 c0 -8 5 -15 10 -15 6 0 10 -18 10 -40 0 -29 4 -40 15 -40
11 0 15 11 15 40 0 29 -4 40 -15 40 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M8285 7660 c-4 -11 -10 -18 -15 -15 -11 6 -1 -66 10 -79 12 -13 42
40 37 66 -4 20 -4 20 -6 -4 0 -16 -6 -28 -12 -28 -8 0 -10 14 -6 40 6 43 2 52
-8 20z"/>
<path d="M8963 7555 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M8313 7510 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M8280 7500 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M8939 7450 c-6 -43 -6 -60 1 -60 12 0 20 44 10 54 -6 6 -10 8 -11 6z"/>
<path d="M8302 7420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8298 7198 c-6 -197 -4 -238 14 -238 5 0 6 16 1 38 -4 20 -6 48 -5
62 6 59 7 249 2 290 -3 25 -9 -44 -12 -152z"/>
<path d="M8800 7370 c0 -6 5 -10 10 -10 6 0 10 -4 10 -10 0 -5 -7 -7 -16 -3
-14 5 -15 -2 -9 -63 4 -38 11 -73 15 -79 16 -22 29 -75 19 -80 -5 -4 -9 -18
-9 -33 1 -23 3 -25 13 -12 19 23 47 21 47 -3 0 -27 6 -20 14 18 5 22 4 26 -4
15 -9 -13 -10 -13 -10 0 0 13 -1 13 -10 0 -9 -13 -10 -13 -11 0 -1 8 -3 26 -5
40 -2 14 -6 47 -9 74 -3 28 -11 56 -17 63 -6 7 -8 27 -4 47 5 25 3 35 -9 40
-8 3 -15 1 -15 -4z"/>
<path d="M8892 7345 c-3 -5 -7 -53 -8 -106 -1 -75 1 -96 12 -96 16 0 18 15 5
36 -6 10 -3 19 10 30 13 11 20 35 25 81 6 63 5 66 -16 66 -12 0 -24 -5 -28
-11z"/>
<path d="M8732 7113 c-23 -9 33 -49 62 -44 5 1 6 -5 3 -14 -4 -8 -1 -15 4 -15
7 0 9 -9 6 -22 -3 -12 -2 -30 4 -40 8 -16 6 -18 -21 -18 -28 0 -30 -2 -30 -40
0 -33 3 -40 20 -40 14 0 20 -7 21 -22 0 -22 1 -22 8 -3 5 11 4 22 0 23 -16 6
-9 22 9 22 16 1 16 1 0 11 -26 15 -22 38 7 45 25 6 33 19 15 26 -5 2 -10 20
-12 41 -1 21 -8 43 -15 49 -7 5 -10 14 -7 19 3 5 0 8 -8 7 -7 -2 -12 3 -10 10
2 12 -31 15 -56 5z"/>
<path d="M2547 7063 c-9 -8 -9 -462 0 -1491 l6 -812 311 2 311 3 44 80 c24 44
57 105 73 135 17 30 45 82 63 115 70 127 90 164 157 290 39 72 86 159 106 195
92 169 148 272 191 350 114 212 136 250 144 250 8 0 8 14 16 -810 l6 -605 313
0 314 0 69 130 c39 72 95 177 126 235 31 58 67 125 81 150 14 25 73 135 132
245 59 110 149 277 200 370 106 196 169 313 217 404 18 35 41 78 52 95 10 17
41 75 70 128 28 54 62 117 75 140 13 24 41 77 63 118 22 41 63 118 92 171 28
53 51 101 51 108 0 8 -78 11 -311 9 l-311 -3 -367 -732 c-221 -443 -372 -733
-380 -733 -12 0 -13 109 -10 733 l4 732 -255 2 c-140 2 -260 0 -266 -5 -10 -7
-169 -303 -572 -1062 -27 -52 -75 -142 -106 -200 -31 -58 -63 -119 -72 -136
-25 -46 -43 -68 -51 -61 -6 7 2 300 32 1076 8 206 12 378 9 383 -6 10 -618 11
-627 1z"/>
<path d="M6391 7059 c-332 -39 -539 -217 -652 -561 -181 -549 -108 -777 266
-833 74 -11 189 -15 440 -15 203 0 355 -4 377 -10 70 -20 84 -81 49 -215 -22
-84 -40 -121 -78 -157 -57 -54 -41 -53 -732 -58 l-644 -5 -20 -75 c-11 -41
-37 -138 -58 -214 -21 -76 -36 -143 -33 -147 3 -5 356 -9 784 -9 l780 0 56 68
c205 247 444 602 559 832 36 71 37 78 32 147 -6 81 -41 169 -85 217 -15 16
-51 39 -81 53 -111 50 -168 56 -596 62 -425 6 -424 6 -464 57 -26 33 -27 79
-5 177 19 82 41 130 79 169 69 73 116 79 693 78 262 0 487 0 499 0 17 0 25 10
37 48 31 94 105 386 100 394 -7 11 -1207 8 -1303 -3z"/>
<path d="M8857 7024 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M8282 6960 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8302 6928 c-15 -15 -16 -48 -2 -48 6 0 10 -4 10 -10 0 -5 -6 -9 -12
-7 -9 1 -12 -9 -12 -30 1 -18 -2 -33 -7 -33 -5 0 -9 12 -10 28 0 24 -1 24 -8
7 -5 -11 -5 -45 0 -75 12 -81 17 -100 31 -118 7 -8 12 -17 11 -19 -8 -10 -1
-43 7 -38 10 6 7 76 -6 194 -4 38 -3 51 5 47 8 -5 11 12 11 53 0 34 -1 61 -3
61 -2 0 -8 -5 -15 -12z"/>
<path d="M8744 6780 c3 -19 3 -30 -2 -25 -6 9 -28 11 -88 6 -12 -1 -15 -8 -12
-26 3 -14 1 -22 -5 -18 -5 3 -7 0 -5 -8 3 -8 15 -13 27 -12 12 2 20 -1 16 -6
-3 -5 0 -13 6 -17 7 -4 9 -3 6 3 -4 6 2 13 13 16 14 4 20 0 20 -11 0 -9 14 10
30 41 32 61 37 83 19 71 -6 -3 -16 0 -21 7 -7 10 -8 3 -4 -21z"/>
<path d="M8225 6650 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8570 6643 c13 -6 13 -50 0 -58 -5 -3 -9 -21 -9 -38 2 -30 2 -31 6
-4 2 15 9 27 14 27 15 0 6 73 -9 76 -7 1 -8 0 -2 -3z"/>
<path d="M8205 6621 c-8 -27 -2 -36 13 -18 6 7 7 18 3 26 -7 10 -10 8 -16 -8z"/>
<path d="M8680 6628 c0 -16 -51 -68 -66 -68 -7 0 -16 -17 -20 -37 -4 -21 -12
-41 -18 -45 -6 -4 -7 -11 -2 -15 5 -4 11 -2 13 5 3 6 11 12 19 12 8 0 14 9 14
19 0 24 16 36 23 19 3 -7 3 2 0 21 -5 29 -4 33 10 28 19 -7 33 8 42 46 5 17 3
27 -4 27 -6 0 -11 -5 -11 -12z"/>
<path d="M8194 6552 c3 -21 2 -31 -4 -27 -6 4 -10 -19 -10 -59 0 -51 3 -66 14
-66 8 0 17 10 19 23 2 12 2 4 -1 -18 -9 -76 -8 -80 16 -83 17 -3 22 2 23 20 1
23 1 23 9 -2 6 -17 8 -19 9 -7 1 10 -6 34 -14 54 -8 19 -16 61 -16 92 -1 31
-4 44 -6 29 -2 -16 -9 -28 -15 -28 -15 0 -2 71 14 83 10 6 9 8 -3 4 -9 -3 -22
0 -29 6 -10 10 -11 5 -6 -21z m54 -177 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z"/>
<path d="M8304 6550 c0 -13 4 -16 10 -10 7 7 7 13 0 20 -6 6 -10 3 -10 -10z"/>
<path d="M8260 6500 c0 -11 5 -20 10 -20 6 0 10 7 10 15 0 8 5 15 11 15 8 0 9
-11 5 -32 -6 -30 -5 -31 8 -13 10 14 11 25 5 37 -14 25 -49 23 -49 -2z"/>
<path d="M8594 6445 c-5 -12 -10 -13 -20 -4 -11 9 -14 6 -14 -15 0 -14 -5 -26
-11 -26 -5 0 -7 -5 -3 -12 6 -9 9 -9 12 0 2 7 9 9 14 6 7 -4 8 -2 4 4 -3 6 2
16 13 21 21 12 36 41 20 41 -5 0 -12 -7 -15 -15z"/>
<path d="M8307 6436 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"/>
<path d="M8140 6396 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M8131 6354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8516 6343 c-11 -27 8 -34 21 -7 9 19 8 24 -2 24 -7 0 -16 -8 -19
-17z"/>
<path d="M8119 6281 c4 -39 5 -42 12 -21 11 39 10 57 -3 63 -10 3 -12 -8 -9
-42z"/>
<path d="M8434 6311 c9 -13 -19 -32 -38 -25 -9 3 -14 9 -11 13 2 4 -3 8 -11 8
-10 0 -11 -4 -5 -10 7 -7 7 -16 0 -29 -7 -14 -6 -18 5 -18 10 0 13 6 9 16 -4
12 -2 15 8 12 8 -3 13 -11 11 -19 -2 -7 10 0 25 17 28 28 32 44 12 44 -6 0 -8
-4 -5 -9z"/>
<path d="M8475 6310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8097 6286 c-5 -15 -6 -31 -3 -34 8 -8 18 25 14 45 -3 13 -6 10 -11
-11z"/>
<path d="M8490 6280 c-9 -16 -8 -18 5 -14 8 4 15 12 15 20 0 20 -7 17 -20 -6z"/>
<path d="M8152 6265 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8418 6230 c12 -5 22 -15 22 -22 0 -9 3 -9 11 -1 17 17 2 33 -29 32
-26 0 -26 -1 -4 -9z"/>
<path d="M8400 6196 c0 -8 5 -18 12 -22 7 -5 4 -10 -11 -16 -12 -4 -19 -12
-16 -18 10 -17 25 -11 37 14 8 20 7 28 -6 41 -14 14 -16 14 -16 1z"/>
<path d="M8122 6181 c-13 -8 -6 -41 8 -41 12 0 22 32 13 41 -5 5 -14 5 -21 0z"/>
<path d="M8070 6169 c0 -5 -4 -8 -9 -4 -5 3 -17 -9 -25 -26 -18 -37 -20 -53
-6 -44 14 9 13 -8 -2 -23 -7 -7 -9 -12 -4 -12 15 0 36 29 37 52 0 21 1 22 8 3
6 -14 8 -8 7 23 0 23 -2 42 -3 42 -2 0 -3 -5 -3 -11z"/>
<path d="M8333 6153 c-18 -6 -16 -23 1 -23 17 0 30 16 19 23 -5 2 -14 2 -20 0z"/>
<path d="M8125 6100 c4 -7 3 -15 -3 -19 -6 -3 -12 -18 -14 -31 -5 -36 -16 -50
-39 -50 -18 0 -20 -4 -14 -32 4 -18 11 -37 17 -43 14 -15 40 25 36 55 -2 15 4
34 14 45 20 21 24 70 6 81 -7 5 -8 3 -3 -6z"/>
<path d="M8338 6103 c-10 -2 -18 -13 -18 -24 0 -11 5 -17 10 -14 6 4 8 11 5
16 -4 5 -1 9 4 9 6 0 11 -4 11 -10 0 -5 5 -10 12 -10 6 0 8 3 5 7 -4 3 -2 12
4 20 12 14 3 16 -33 6z"/>
<path d="M8150 6062 c0 -5 7 -15 15 -22 17 -14 58 -4 52 13 -4 14 -67 22 -67
9z m50 -12 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
15 -10z"/>
<path d="M7983 6024 c-7 -14 -10 -39 -7 -54 4 -16 1 -31 -5 -35 -6 -3 -10 -3
-9 2 2 4 -1 9 -7 11 -5 2 -11 -4 -13 -12 -3 -12 5 -16 29 -16 31 0 32 0 26 41
-5 39 -4 41 19 36 27 -5 47 12 35 32 -7 10 -13 10 -29 2 -16 -9 -21 -8 -24 4
-2 10 -7 6 -15 -11z"/>
<path d="M8190 5996 c0 -7 47 -51 56 -52 5 -1 19 11 32 27 13 16 19 29 13 29
-6 0 -11 -4 -11 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -5 -5 -7 -11
-4 -6 5 -4 11 7 19 14 11 12 13 -20 14 -20 1 -36 0 -36 -2z"/>
<path d="M8160 5936 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M8173 5913 c-18 -7 -16 -42 2 -49 16 -6 57 35 48 48 -5 9 -31 9 -50
1z m19 -35 c-6 -6 -14 -9 -17 -6 -3 3 -3 13 1 22 5 12 10 14 17 7 8 -8 8 -14
-1 -23z"/>
<path d="M7890 5850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8033 5834 c-19 -20 -16 -34 7 -31 33 5 45 24 24 35 -14 7 -23 6 -31
-4z"/>
<path d="M8090 5830 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8130 5827 c0 -7 -8 -20 -17 -29 -34 -32 -41 -45 -32 -59 7 -12 9
-11 9 1 0 9 14 27 30 42 29 25 40 58 20 58 -5 0 -10 -6 -10 -13z"/>
<path d="M7907 5822 c-10 -2 -17 -11 -16 -20 3 -38 0 -45 -16 -32 -13 11 -15
9 -12 -11 2 -13 10 -24 18 -24 8 0 13 6 12 13 -2 6 4 12 13 12 10 0 14 6 11
14 -3 8 0 19 7 25 9 7 17 3 30 -14 11 -14 24 -25 31 -25 31 0 33 -67 3 -80
-18 -7 -19 -9 -5 -9 9 -1 17 -9 17 -18 0 -14 3 -14 12 -5 10 10 10 15 -2 23
-12 8 -10 9 7 4 17 -5 22 -3 17 8 -3 9 3 17 18 22 19 6 20 8 6 12 -21 5 -23
14 -6 31 9 9 8 12 -4 12 -9 0 -18 -6 -21 -12 -2 -7 -8 -10 -12 -6 -4 4 -3 14
3 21 9 10 8 18 -2 32 -12 17 -14 17 -31 -6 -11 -16 -15 -18 -11 -6 3 10 2 16
-3 13 -5 -3 -11 2 -13 12 -5 19 -16 22 -51 14z"/>
<path d="M7810 5710 c-12 -16 -17 -31 -11 -31 6 -1 16 -2 22 -3 7 -1 13 14 15
32 5 40 1 40 -26 2z"/>
<path d="M7927 5674 c11 -18 12 -28 4 -37 -8 -10 -7 -17 2 -26 9 -10 9 -12 -3
-11 -31 5 -38 -1 -45 -37 -4 -21 -12 -45 -18 -53 -11 -13 -10 -13 8 0 31 24
64 64 65 78 0 7 5 10 10 7 6 -3 10 -2 10 4 0 5 -5 11 -12 13 -14 5 -4 38 12
38 5 0 10 -7 10 -16 0 -8 5 -12 11 -8 7 4 1 16 -16 34 -19 20 -23 29 -13 32 7
3 1 5 -14 5 -26 0 -26 0 -11 -23z"/>
<path d="M7880 5675 c-7 -8 -11 -15 -9 -16 31 -6 39 -6 39 2 0 5 -5 9 -11 9
-5 0 -7 5 -4 10 9 15 -1 12 -15 -5z"/>
<path d="M7779 5648 c-1 -7 -1 -19 0 -25 1 -7 -5 -13 -14 -13 -12 0 -14 -6 -9
-27 7 -25 6 -27 -9 -14 -10 7 -17 9 -17 3 0 -14 26 -33 34 -24 4 4 5 -15 1
-43 -6 -49 -6 -50 12 -29 10 12 26 33 36 47 12 17 25 24 35 20 14 -5 15 -3 5
8 -7 7 -13 22 -13 34 0 16 2 17 10 5 7 -12 12 -12 22 -2 9 9 9 12 0 12 -9 0
-10 5 -2 19 7 14 7 22 -2 27 -6 4 -14 2 -16 -4 -2 -6 -10 -8 -18 -5 -9 3 -14
-2 -14 -17 0 -18 -3 -20 -14 -11 -8 7 -12 21 -9 31 3 11 0 20 -6 20 -6 0 -12
-6 -12 -12z"/>
<path d="M7707 5533 c-4 -3 -7 -19 -7 -35 0 -15 -5 -28 -11 -28 -7 0 -9 -10
-5 -24 3 -14 2 -28 -4 -31 -5 -3 -10 3 -10 14 0 12 -7 21 -16 21 -8 0 -12 -5
-9 -10 3 -6 -1 -13 -9 -16 -9 -3 -16 -10 -16 -16 0 -5 -7 -16 -15 -24 -8 -9
-15 -25 -15 -37 0 -13 -7 -33 -16 -45 -13 -19 -13 -22 -1 -22 18 0 27 21 27
61 0 24 4 29 23 29 20 -1 20 -2 5 -11 -12 -7 -18 -22 -18 -49 0 -37 -2 -40
-29 -40 -16 0 -34 -7 -41 -15 -9 -11 -15 -12 -20 -5 -5 7 -11 6 -20 -5 -10
-12 -10 -15 4 -15 10 0 15 -4 12 -9 -4 -5 -3 -18 1 -29 7 -17 10 -18 21 -6 7
8 8 14 2 14 -5 0 -10 6 -10 13 0 17 43 41 61 35 16 -5 49 21 49 39 0 7 11 15
24 19 14 3 27 12 30 21 4 11 1 13 -14 8 -15 -5 -20 -2 -20 10 0 13 7 16 29 13
17 -2 28 -1 25 3 -2 4 4 10 13 13 33 11 119 100 115 119 -4 18 -5 18 -23 1
-10 -9 -19 -25 -19 -34 0 -23 -31 -50 -37 -32 -3 6 -9 12 -14 12 -6 0 -7 -5
-4 -10 4 -6 -5 -23 -19 -37 -22 -22 -26 -23 -26 -8 0 10 7 27 17 37 9 11 11
18 5 18 -7 0 -12 5 -12 10 0 6 7 10 16 10 8 0 12 5 9 10 -3 6 -2 10 3 10 13 0
3 36 -13 50 -6 5 -15 7 -18 3z m-35 -155 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12
12 19 0 21 -3 12 -12z m-22 -38 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
<path d="M7541 5300 c-12 -17 -17 -30 -12 -30 10 0 45 50 39 56 -3 2 -14 -10
-27 -26z"/>
<path d="M7555 5220 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M7472 5202 c2 -5 -8 -19 -22 -31 l-25 -21 35 14 c35 14 46 36 22 44
-7 2 -11 0 -10 -6z"/>
<path d="M7423 5134 c-13 -10 -14 -13 -4 -14 19 0 -16 -40 -36 -40 -7 0 -13
-4 -13 -10 0 -13 13 -13 27 1 6 6 22 17 35 23 12 7 22 18 21 24 -2 7 3 12 11
12 8 0 17 4 20 9 9 14 -42 10 -61 -5z"/>
<path d="M1483 4275 c3 -33 148 -693 163 -747 5 -16 20 -18 128 -18 l122 0 28
103 c16 56 47 169 70 251 l41 149 13 -44 c7 -24 39 -136 70 -249 l57 -205 124
0 124 0 48 220 c27 121 65 291 84 377 19 86 35 166 35 177 0 20 -5 21 -113 21
l-113 0 -38 -202 c-21 -112 -42 -209 -46 -216 -6 -12 -46 120 -107 351 l-17
68 -119 -3 -119 -3 -13 -40 c-7 -22 -27 -94 -45 -160 -64 -237 -62 -232 -71
-210 -7 17 -79 388 -79 407 0 4 -52 8 -116 10 l-116 3 5 -40z"/>
<path d="M2866 4233 c-16 -43 -43 -116 -61 -163 -35 -90 -116 -305 -171 -455
-19 -49 -34 -93 -34 -97 0 -5 56 -8 125 -8 l124 0 16 52 c8 28 15 58 15 65 0
11 29 13 147 11 l148 -3 16 -60 16 -60 131 -3 132 -3 -11 28 c-6 15 -64 170
-129 343 -65 173 -128 341 -141 373 l-22 57 -136 0 -136 0 -29 -77z m208 -271
c21 -70 41 -133 43 -139 4 -10 -16 -13 -79 -13 -47 0 -87 3 -90 6 -4 3 2 31
12 62 10 31 28 92 41 135 12 42 25 77 28 77 3 0 23 -57 45 -128z"/>
<path d="M3550 3910 l0 -401 248 3 c243 3 248 3 304 30 69 32 122 86 156 158
22 46 27 73 30 169 9 220 -48 345 -187 410 -55 26 -60 26 -303 29 l-248 3 0
-401z m424 196 c49 -32 66 -84 66 -203 0 -168 -36 -213 -172 -213 l-68 0 0
221 0 222 74 -5 c46 -3 85 -11 100 -22z"/>
<path d="M4556 4203 c-21 -60 -53 -146 -71 -193 -51 -130 -185 -490 -185 -496
0 -2 56 -4 125 -4 l124 0 19 63 18 62 146 3 145 3 13 -62 c7 -33 18 -63 25
-65 6 -3 65 -3 132 -2 l121 3 -148 395 -148 395 -138 3 -139 2 -39 -107z m221
-245 c22 -73 38 -136 36 -140 -5 -9 -157 -11 -165 -3 -6 7 75 275 83 275 3 0
24 -60 46 -132z"/>
<path d="M5120 4210 l0 -100 125 0 125 0 0 -300 0 -300 125 0 125 0 2 301 3
300 120 2 120 2 3 98 3 97 -376 0 -375 0 0 -100z"/>
<path d="M6122 4283 c-10 -28 -260 -692 -281 -745 l-11 -28 128 0 127 0 19 65
18 65 143 0 143 0 17 -65 18 -65 128 0 c71 0 129 3 129 8 0 8 1 5 -134 367
-54 143 -112 296 -130 340 l-32 80 -136 3 -136 3 -10 -28z m186 -313 c16 -52
33 -110 37 -127 l7 -33 -86 0 c-50 0 -86 4 -86 10 0 5 13 51 29 102 16 51 35
113 42 138 14 53 13 53 57 -90z"/>
<path d="M8131 3774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7452 3740 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
